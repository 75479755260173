import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Redirect, useMatch, navigate } from '@reach/router';
import { parse } from 'query-string';

import { context as userContext } from 'context/user';
import { context as featureAccessContext } from 'context/feature-blocking';
import { useSelectedStore, useGtm, useDeliveryZoneMsToken } from 'hooks/index';
import * as userService from 'services/user';
import { Loader } from 'components/kit';
import { getCookie, replaceParams, clarity } from 'utils';
import * as paths from 'paths.js';
import { CAMPAIGN_PERMISSIONS } from 'constants/users';
import FeatureAccess from 'common/lib/FeatureAccess';
import Head from './Head';
import { redirectTo } from './utils';
import * as schemas from './schemas';

export default props => {
  const { signOut } = useContext(userContext);
  const { setStores } = useContext(userContext);
  const storesQuery = useQuery(schemas.LIST, {
    variables: {
      sort: {
        order: 'asc',
        field: 'title-en',
      },
    },
    onCompleted: data => setStores(data.restaurants || []),
    onError: err => {
      if (err.graphQLErrors[0].extensions.code === 401) {
        signOut();
        return;
      }

      throw err;
    },
  });
  const stores = (storesQuery.data && storesQuery.data.restaurants) || [];

  useDeliveryZoneMsToken();

  if (storesQuery.loading || storesQuery.error) return <Loader />;

  return <PageWithStores stores={stores} {...props} />;
};

const PageWithStores = ({ name, stores, guard, component: Component, ...props }) => {
  const {
    user,
    setUnpaidInvoices,
    setUser,
    setBranches,
    setSelectedStore,
    setLegalData,
    setSettings,
    subscriptionStatus,
    branches,
    campaignsPermission,
    setCampaignsPermission,
  } = useContext(userContext);
  const { setFeatureAccess } = useContext(featureAccessContext);
  const searchParams = parse(location.search);
  const storeIdFromFacebookCallback = searchParams.state || sessionStorage.getItem('facebook_business_id');
  const selectedStoreId = storeIdFromFacebookCallback || useSelectedStore() || stores[0].id;
  const match = useMatch(paths.campaigns + '/*');

  const userQuery = useQuery(schemas.USER, {
    variables: {
      storeId: selectedStoreId,
      id: userService.getUserId(),
    },
    onCompleted: ({ user, branches, restaurant, invoices, settings, legalData }) => {
      setUser(user);
      setBranches(branches);
      setSelectedStore(restaurant);
      setFeatureAccess(FeatureAccess.createAccessMap(restaurant.subscriptionPlanType));
      setLegalData(legalData);
      setSettings(settings);
      setUnpaidInvoices(invoices);
      if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
        if (getCookie('ajs_user_id') === '') {
          window.analytics.identify(
            user.id,
            {
              createdAt: user.createdAt,
              name: user.name,
              email: user.email,
              phone: user.phoneNumber,
              roles: user.roles,
              isSupport: user.isSupport,
            },
            {},
            () => {
              if (window.Vitally) {
                window.Vitally.account({
                  accountId: selectedStore.id,
                  traits: {
                    name: selectedStore.titleEn,
                  },
                });
                window.Vitally.user({
                  userId: user.id,
                  accountId: selectedStore.id,
                  traits: {
                    name: user.name,
                    email: user.email,
                    createdAt: user.createdAt && new Date(user.createdAt).toISOString().split('T')[0],
                  },
                });
                window.Vitally.nps('survey', {
                  userId: user.id,
                  productName: 'Zyda',
                });
              }
            },
          );
        }
      }
      clarity.identify({ id: user.id, email: user.email, name: user.name, phoneNumber: user.phoneNumber });
    },
    onError: err => {
      throw err;
    },
  });
  useQuery(schemas.PERMISSION, {
    variables: {
      storeId: selectedStoreId,
    },
    onCompleted: ({ campaignsPermission }) => {
      setCampaignsPermission(campaignsPermission);
    },
    onError: () => {
      setCampaignsPermission(CAMPAIGN_PERMISSIONS.FALSE);
    },
  });
  const selectedStore = (userQuery.data && userQuery.data.restaurant) || {};
  const { blockTabs } = selectedStore || false;

  // remove premission.loading as temp fix
  if (userQuery.loading || !user) return <Loader />;

  const payload = {
    isStoreCreated: stores.length > 0,
    blockTabs,
    subscription: subscriptionStatus,
    storeId: selectedStoreId,
  };

  if (!guard(true, payload)) {
    return <Redirect noThrow to={redirectTo(true, payload)} />;
  }

  if (match && [CAMPAIGN_PERMISSIONS.NOT_SUPPORTED_PLAN, CAMPAIGN_PERMISSIONS.FALSE].includes(campaignsPermission)) {
    return <Redirect noThrow to={redirectTo(true, payload)} />;
  }

  if (storeIdFromFacebookCallback) {
    navigate(replaceParams(paths.socialmediatracking, { storeId: storeIdFromFacebookCallback }));
  }

  return (
    <PageWithUser
      user={user}
      name={name}
      selectedStore={{ numberOfBranches: branches?.length, ...selectedStore }}
      component={Component}
      {...props}
    />
  );
};

const PageWithUser = ({ user, name, selectedStore, component: Component, ...props }) => {
  const { pageView } = useGtm();

  useEffect(() => name && pageView(name && name[0]), []);

  return (
    <Head user={user} name={name} store={selectedStore}>
      <Component {...props} />
    </Head>
  );
};
